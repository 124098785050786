// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dw_Cw";
export var mobileAppCardTechStack__card = "dw_Cx";
export var mobileAppCardTechStack__description = "dw_Cz";
export var mobileAppCardTechStack__iconsMobileDev = "dw_CB";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dw_CG";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dw_CD";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dw_CF";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dw_CC";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dw_CH";
export var mobileAppCardTechStack__title = "dw_Cy";