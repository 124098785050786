// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "D_f7";
export var devBenefitBussinesMobileDev = "D_f3";
export var domainExpertiseMobileDev = "D_fZ";
export var mobileAppDevTechnologies = "D_fW";
export var mobileAppDevTechnologies__wrapper = "D_fX";
export var mobileAppProjLogoSection = "D_f5";
export var ourKeyPrinciplesMobileDev = "D_f2";
export var serviceMobileTestimonialPost = "D_f1";
export var servicesCardBorderIconMobileDevSection = "D_fT";
export var servicesCardWithTopBorderSectionMobileApp = "D_fV";
export var servicesProcessMobileDevSection = "D_f0";
export var successStoriesMobileSection = "D_f6";
export var weBuildProjectsDifferentSection = "D_fY";
export var whyChooseServicesMobile = "D_f4";